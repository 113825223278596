import React, { useRef } from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row } from "react-bootstrap"
import dnicon1 from "../images/dnicon1.png"
import dnicon2 from "../images/dnicon2.png"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Slider from "react-slick"

import ban1 from "../images/supportUs/banner.png"
import ann9 from "../pdfs/annual/annual-report-2019-20.pdf"

const SupportUs = () => {
  const car = useRef()
  const settingsCar = {
    dots: true,
    dotsClass: "slick-dots fel-indi ban-indi laf-fel-indi",
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }
  return (
    <Layout pageName="Support us">
      <SEO title="Support Us" />
      <Row className="mx-0 z-50">
        <div className="col-lg-6 order-lg-last px-0 jtf-img halfwayBannerColor">
          <Slider {...settingsCar} className="laf-fel" ref={car}>
            <img className="w-100" src={ban1} />
          </Slider>
        </div>
        <div className="col-lg-6 px-0 bg-sec align-center">
          <div className="jtf-cont ">
            <div className="text-center text-lg-left">
              <p className="ed-title">Support Us</p>
              <p className="fi-head mb-lg-3 pr-lg-5 mb-3">
                Donate to the Teach For India Movement
              </p>
              <p className="fi-txt imp-txt ">
                Contribute and help us in our fight against educational inequity
                in India. Your donation will fuel our leaders into the action
                required to reach our goal for all children. For US-based donors
                looking to donate to our US 501(c)3 entity, please visit Friends
                of Teach For India.
              </p>
              <p>
                <Link
                  to="/donate"
                  className="butn butn-main mr-2 donate-butn butn-sm-sp"
                >
                  DONATE
                </Link>
                <a
                  href="https://www.tfius.org/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="butn butn-main donate-butn butn-sm-sp"
                >
                  DONATE (USA)
                </a>
              </p>
            </div>
          </div>
        </div>
      </Row>
      <Container className="z-999 text-center">
        <img src={dnicon1} className="dn-icon" />
      </Container>
      <Container>
        <div className="text-center mtneg-50 pb-lg-5">
          <p className="fi-head max-60 max-sm-100 mx-auto">
            How Will Your Money Enable Change?
          </p>
          <p className="fi-txt max-80 max-sm-100 mx-auto">
            Out of every rupee that is donated to Teach For India, 87 paise goes
            towards classroom expenses like Fellow salary and allowances,
            classroom resources, field trips etc. and 13 paise goes towards the
            operational expenses of the Teach For India Fellowship program.
          </p>
          <p className="fi-txt max-80 max-sm-100 mx-auto mb-lg-5">
            Teach For India is committed to and practices good governance. It is
            accountable and transparent to various stakeholders and has received
            accreditation from The Credibility Alliance, GiveIndia, the National
            CSR Hub, Global Giving, and is NGO source verified. Our 2020-21
            Annual Report contains an overview of all the main activities across
            different verticals, a review of the progress and results achieved
            in the year, information on our Staff and Board members,
            remuneration, reimbursement and other financial details.
          </p>
          <p className="mb-4">
            <Link
              to="/donate"
              className="butn butn-main mr-2 donate-butn butn-sm-sp"
            >
              DONATE
            </Link>
            <a
              href="https://www.tfius.org/"
              target="_blank"
              rel="noreferrer noopener"
              className="butn butn-main mr-2 donate-butn butn-sm-sp"
            >
              DONATE (USA)
            </a>
            <a
              href={ann9}
              target="_blank"
              rel="noopener noreferrer"
              className="butn butn-main donate-butn butn-sm-sp"
            >
              ANNUAL REPORT
            </a>
          </p>
        </div>
      </Container>
      <Container>
        <div className="text-center pb-5">
          <img src={dnicon2} className="dn-icon2" />
          <p className="fi-head">Our Partners</p>
          <p className="fi-txt max-80 max-sm-100 mx-auto mb-lg-5">
            One day all children will attain an excellent education isn’t an
            easy feat. We need partners and change makers fuelling it from all
            levels. Teach For India gratefully acknowledges the support of the
            following individuals, foundations, and corporations who have
            championed our efforts.
          </p>
          <p className="mb-4">
            <AnchorLink
              to="/our-partners#donors"
              title="OUR DONORS"
              className="butn butn-main mr-2 donate-butn butn-sm-sp"
              stripHash
            />
            <AnchorLink
              to="/our-partners#strategic-partners"
              title="STRATEGIC PARTNERS"
              className="butn butn-main donate-butn butn-sm-sp"
              stripHash
            />
          </p>
        </div>
      </Container>
    </Layout>
  )
}

export default SupportUs
